/*
* Starting Date: 25/01/2023
* Redesign by: Md. Abul Maruf Suborno 
* Verson
*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

::selection {
    background: green;
    color: white;
}

td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

:root {
    --color-1: white;
    --color-2: black;
    --color-3: #FF6600;
    --color-4: red;
    --color-5: blue;
    --color-6: green;
    --color-7: tomato;
    --color-8: slateblue;
    --color-9: teal;
    --Bg-color-1: #683091;
    --Bg-color-2: #4ba614;
    --Bg-color-3: #e6e1e1;
}

.rounded {
    border-radius: 50%;
}

.my-4 {
    margin: 15px 0;
}

.gn-btn {
    color: teal;
    cursor: pointer;
    padding: 2px 10px;
    border-radius: 3px;
    font-size: 12px;
    background-color: #4ba614;
    color: #ffffff !important;
    border: 1px solid #34740e;
    font-weight: bold;
    margin-bottom: 0px;
}

.d-none {
    display: none;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.w-40 {
    width: 40%;
}

.history-tittle {
    font-size: 1.5rem;
    margin: 10px 0;
}

.history {
    @media (max-width: 968px) {
        padding: 0 10px;
        margin-bottom: 10px;
    }

    .full_img {
        width: 100%;
    }

    p {
        text-align: justify;
    }
}

.pageTittle {
    @media (max-width: 968px) {
        padding: 0 5px;
    }

    * {
        font-size: 1.2rem;

        a {
            text-decoration: none;
        }
    }
}

#theme-body {
    background: url("../img/bg_main.gif") repeat-y scroll center top rgba(255, 255, 255, 0);
}

.marqeeText {
    font-size: 0.8rem;
    color: red;
}

.styleList {
    li {
        list-style: none;
        background: url("../img/bullet_tick.png") no-repeat center left;
        padding-left: 15px;
        line-height: 20px;

        a {
            font-size: 0.8rem;
            color: rgb(63, 62, 62);
            text-decoration: none;
            border-bottom: 1px dotted #666;

            &:hover {
                color: var(--color-2);
                border-bottom: 1px solid rgb(8, 8, 8);
            }
        }
    }
}

.Bg-color-1 {
    background: var(--Bg-color-1);
}

.Bg-color-2 {
    background: var(--Bg-color-2);
}

.text-right {
    text-align: right;
}

.mb-2 {
    margin-bottom: 4px;
}

.contaier {
    max-width: 960px;
    margin: 0 auto;
}

.row {
    display: flex;
}

.col-md-8 {
    width: 75%;
}

.col-md-4 {
    width: 25%;
}

.justify-space-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

// margin
.mx-2 {
    margin: 0 4px;
}

// padding
.p-2 {
    padding: 2px;
}

.topHeader {
    padding: 5px;
    border-bottom: var(--Bg-color-2)solid 4px;
    z-index: 11;

    .row {
        @media (max-width: 668px) {
            display: block;

            .col-6 {
                margin: 15px 0;
            }
        }
    }

    .slide-panel-btns {

        .slide-panel-button {
            margin-right: 10px;

            a {
                color: var(--color-1);
                text-decoration: none;
                margin: 5px;
                font-size: .7em;
            }
        }
    }

    .searchBar {
        height: 20px;
        border-radius: 5px;
        width: 100px;
    }

    button {
        font-size: 0.8em;
    }

    #div-lang {
        .sectionPart {
            display: flex;

            select {
                padding: 3px;
                color: #444;
                line-height: 22px;
                font-size: .7em;
                text-align: left;
                margin: 0 5px;
                border-radius: 5px;
                max-width: 150px;
            }

            #officeTypeBTN {
                text-decoration: none;
                font-size: .7em;
                padding: 4px 8px;
            }
        }
    }
}

// Slider Part
.sliderPart {
    width: 100%;
    max-height: 225px;
    background: rgb(194, 194, 194);
    overflow: hidden;
    position: relative;
    z-index: 11;

    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                img {
                    width: 100%;
                }
            }
        }
    }

    .logo_text {
        position: absolute;
        bottom: 40px;
        left: 50px;
        z-index: 1;

        a {
            text-decoration: none;

            img {
                height: 80px;
            }

            .text {
                margin: 0 25px;
                display: grid;
                align-items: center;
                justify-content: start;
                filter: drop-shadow(1px 1px 2px black);

                h1 {
                    color: var(--color-1);
                    font-size: 1.2rem;
                    font-weight: bold;

                    @media (max-width: 768px) {
                        font-size: 1rem;

                        span {
                            span {
                                width: 200px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Main Header Part
.mainHeader {
    // height: 37px;
    display: flex;
    align-items: center;
    z-index: 11;
    position: relative;

    @media (max-width: 968px) {
        margin-bottom: 4px;
    }

    nav {
        width: 100%;
        position: relative;
        background-color: var(--Bg-color-3);
        display: flex;

        @media (max-width: 968px) {
            padding: 5px;

            &.active {
                ul {
                    height: auto;
                    transition: 0.5s ease-in-out;
                }
            }
        }

        .toggle_btn {
            z-index: 1;
            padding: 0 10px;

            @media (min-width: 968px) {
                display: none;
            }

            button {
                width: 35px;
                height: 35px;
                color: var(--color-2);
                transform: rotate(90deg);
                font-size: 20px;
            }
        }

        ul {
            width: 100%;
            display: flex;
            align-items: center;
            clear: both;
            height: 100%;
            background-color: #eeecec;
            border-bottom: 1px dotted #CCCCCC;

            @media (max-width: 968px) {
                overflow: hidden;
                height: 0;
                margin: 0;
                display: block;
                transition: 0.5s ease-in-out;
                margin-top: 5px;
            }

            li {
                list-style: none;
                padding: 10px;
                height: 40px;
                border-left: 1px dotted #CCCCCC;
                position: relative;
                font-size: 0.9em;
                cursor: pointer;
                user-select: none;

                @media (max-width: 968px) {
                    height: auto;
                }

                &:hover {
                    .subMenu {
                        height: auto;
                        transition: 0.5s ease;
                        border-top: 5px solid black;

                        @media (min-width: 768px) {
                            transform: translateY(0px);
                        }

                        @media (max-width: 968px) {
                            border: 0;
                        }
                    }
                }

                .subMenu {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    overflow: hidden;
                    height: 0;
                    background: var(--color-1);
                    z-index: 1;
                    transition: 0.5s ease;

                    @media (min-width: 968px) {
                        transition: 0.5s ease;
                        transform: translateY(80px);
                    }

                    @media (max-width: 968px) {
                        position: unset;
                        display: block;
                        margin-top: 10px;
                    }

                    ul {
                        display: block;
                        padding: 10px;
                        background: var(--color-1);
                        border: 1px solid #ccc;

                        @media (max-width: 968px) {
                            border-top: 0;
                        }

                        li {
                            border: 0;
                            border-bottom: 1px dotted #ccc;

                            &:hover {
                                background: rgba(204, 204, 204, 0.178);

                                a {
                                    color: #444;
                                }
                            }

                            a {
                                color: var(--color-2);
                                white-space: nowrap;
                                font-size: 0.8rem;
                            }
                        }
                    }
                }

                &.end {
                    @media (min-width: 968px) {
                        .subMenu {
                            transform: translateX(-60%);
                        }
                    }
                }

                &.orange {
                    color: var(--color-3);

                    &:hover {
                        color: var(--color-1);
                        background: var(--color-3);

                        .subMenu {
                            border-top: 5px solid orangered;

                            ul {
                                li {
                                    &:hover {
                                        a {
                                            color: var(--color-3);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    a {
                        color: var(--color-3);
                    }
                }

                &.red {
                    color: var(--color-4);

                    &.active {
                        background: red;

                        a {
                            color: white;
                        }
                    }

                    &:hover {
                        background: var(--color-4);

                        .subMenu {
                            border-top: 5px solid red;

                            ul {
                                li {
                                    &:hover {
                                        a {
                                            color: var(--color-4);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    a {
                        color: var(--color-4);
                    }
                }

                &.blue {
                    color: var(--color-5);

                    &:hover {
                        background: var(--color-5);

                        .subMenu {
                            border-top: 5px solid blue;

                            ul {
                                li {
                                    &:hover {
                                        a {
                                            color: var(--color-5);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    a {
                        color: var(--color-5);
                    }
                }

                &.green {
                    color: var(--color-6);

                    &:hover {
                        background: var(--color-6);

                        .subMenu {
                            border-top: 5px solid green;

                            ul {
                                li {
                                    &:hover {
                                        a {
                                            color: var(--color-6);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    a {
                        color: var(--color-6);
                    }
                }

                &.tomato {
                    color: var(--color-7);

                    &:hover {
                        background: var(--color-7);

                        .subMenu {
                            border-top: 5px solid tomato;

                            ul {
                                li {
                                    &:hover {
                                        a {
                                            color: var(--color-7);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    a {
                        color: var(--color-7);
                    }
                }

                &.slateblue {
                    color: var(--color-8);

                    &:hover {
                        background: var(--color-8);

                        .subMenu {
                            border-top: 5px solid slateblue;

                            ul {
                                li {
                                    &:hover {
                                        a {
                                            color: var(--color-8);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    a {
                        color: var(--color-8);
                    }
                }

                &:hover {
                    background: #000;
                    color: var(--color-1);

                    a {
                        color: var(--color-1);
                    }
                }

                a {
                    font-size: 0.9rem;
                    color: var(--color-2);
                    text-decoration: none;
                }
            }
        }
    }
}

// Main container
.mainContentPart {
    @media (min-width: 968px) {
        display: grid;
        grid-template-columns: 1fr 250px;
        grid-gap: 15px;
        padding: 10px 0;
    }

    .colam {
        .tittle {
            h1 {
                font-size: 1.5rem;
                margin: 15px 0;
            }
        }

        .memberBox {
            @media (max-width: 968px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                // grid-gap: 10px;
            }

        }
    }
}

.memderCard {
    width: 100%;
    text-align: center;

    @media (max-width: 668px) {
        padding: 5px;
    }

    &.audioBox {
        .audio {
            audio {
                width: 100%;
            }
        }
    }

    .tittle {
        background: var(--Bg-color-2);
        color: var(--color-1);
        padding: 8px;
        text-align: left;
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .img {
        margin: 0px auto;

        img {
            width: 80%;

            @media (max-width: 968px) {
                height: 250px;
                width: auto;
            }

            @media (max-width: 524px) {
                height: 150px;
                width: auto;
            }
        }

        &.helpline_img {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    &.helpline {
        .img {
            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    .reg {
        span {
            font-size: 0.7rem;
        }
    }

    a {
        width: 100%;
        background: linear-gradient(rgb(37, 37, 37), rgb(155, 152, 152));
        color: var(--color-1);
        padding: 3px 5px;
        text-decoration: none;
        margin: 10px 0;
        font-size: 0.7rem;
        display: block;
    }
}

// Notice bord
.notice-board-bg {
    width: 100%;
    min-height: 250px;
    background: var(--Bg-color-3) url(../img/bg_notice_board.png) no-repeat;
    border: 1px solid #dddddd;
    font-size: 1em;
    padding: 0 0 10px 0;
    padding: 20px 20px 0px 120px;
    position: relative;

    @media (max-width: 568px) {
        padding: 90px 20px 0px 55px;
        min-height: 320px;
    }

    @media (max-width: 425px) {
        padding: 90px 20px 0px 20px;
        height: 320px;
    }

    h2 {
        font-size: 1.2rem;
        font-weight: 100;
    }

    .notice-board-ticker {
        margin-top: 15px;
        margin-left: 35px;

        .styleList {
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                -webkit-line-clamp: 1;
                margin: 5px 0;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: 1.58;

                span {
                    background: red;
                    padding: 0px 9px;
                    color: #ffffff;
                    font-size: 14px;
                    display: none;
                }

                &:nth-child(1) {
                    span {
                        display: flex;
                    }
                }

                &:nth-child(2) {
                    span {
                        display: flex;
                    }
                }

                &:nth-child(3) {
                    span {
                        display: flex;
                    }
                }

            }
        }

        .btn {
            background: linear-gradient(rgb(37, 37, 37), rgb(155, 152, 152));
            color: var(--color-1);
            text-decoration: none;
            padding: 2px 10px;
            font-size: 0.8rem;
            position: absolute;
            bottom: 15px;
            right: 15px;
        }
    }
}

// ES Links
.esLinks {
    width: 100%;

    @media (max-width: 668px) {
        padding: 5px;
    }

    .card {
        width: 100%;

        .tittle {
            background: var(--Bg-color-2);
            color: var(--color-1);
            padding: 8px;
            text-align: left;
            font-size: 0.9rem;
            font-weight: bold;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 15px;
            width: 100%;

        }
    }
}

// Footer
.footer-artwork {
    width: 100%;
    height: 80px;
    background: url(../img/footer_top_bg.png) no-repeat;
}

.footer-wrapper {
    padding: 25px;
    background: var(--Bg-color-3);

    @media (min-width: 968px) {
        display: grid;
        grid-template-columns: 1fr 1fr;

    }

    .footerMenu {
        .LastUpdate {
            p {
                font-size: 13px;
                margin: 5px 15px;
            }
        }

        ul {
            display: flex;

            li {
                list-style: none;
                padding: 5px 15px;
                border-right: 1px dotted #9c9a9a;

                &:hover {
                    background: #000;

                    a {
                        color: var(--color-1);
                    }
                }

                a {
                    text-decoration: none;
                    font-size: 0.8rem;
                    color: var(--color-2);
                }
            }
        }
    }

    .suporters {
        display: flex;
        justify-content: center;
        padding: 0 25px;
        flex-direction: column;
        align-items: center;
        align-content: center;

        @media (max-width: 668px) {
            margin-top: 20px;
            padding: 0;
            text-align: left;

            ul {
                margin-bottom: 10px;
                padding: 0 15px !important;
                align-items: center;
                flex-wrap: wrap;
            }
        }

        .developers {
            text-align: right;

            p {
                font-size: 12px;
                margin: 5px 0;
            }
        }

        ul {
            display: flex;
            padding: 0 25px;

            li {
                list-style: none;
                font-size: 0.6rem;

                &:nth-child(1) {
                    margin-right: 5px;
                }

                a {
                    text-decoration: none;
                    font-size: 0.6rem;
                    margin: 0 3px;
                }
            }
        }
    }
}

// Info Card
.InfoCardsPart {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    @media (max-width: 668px) {
        grid-template-columns: 1fr;
        margin-bottom: 15px;
        padding: 0 5px;
    }

    .infoCard {
        background: var(--Bg-color-3);
        padding: 15px;

        .tittle {
            margin-bottom: 10px;

            h2 {
                font-size: 1rem;
                font-weight: normal;
            }
        }

        .imgText {
            display: flex;
            align-items: center;

            img {
                margin-right: 15px;
                max-width: 100px;
            }
        }

    }
}

// News Part
.newsCard {
    background: var(--Bg-color-3);
    padding: 15px;
    margin: 15px 0;
    display: flex;
    height: auto;
    margin-top: 0px;

    .tittle {
        * {
            font-size: 0.8rem;
        }
    }

    ul {
        width: 100%;
        height: 80px;
        padding: 0 5px;
        overflow: hidden;

        li {
            list-style: none;
            width: 100%;
            height: 100px;
            text-overflow: clip;
            overflow: hidden;
            transform: translateY(-100px);
            animation: newShortAnimi 25s infinite;
            transition: 0.8s ease all;

            @keyframes newShortAnimi {
                0% {
                    transform: translateY(0);
                }

                20% {
                    transform: translateY(-100px);
                }

                40% {
                    transform: translateY(-200px);
                }

                60% {
                    transform: translateY(-300px);
                }

                80% {
                    transform: translateY(-400px);
                }

                100% {
                    transform: translateY(-400px);
                }
            }

            &:hover {
                * {
                    text-decoration: 1px dotted;
                }
            }

            * {
                font-size: 0.8rem;
                color: rgb(85, 84, 84);
                text-decoration: none;
            }
        }
    }

    a.btn {
        background: linear-gradient(rgb(37, 37, 37), rgb(155, 152, 152));
        color: var(--color-1);
        padding: 3px 5px;
        text-decoration: none;
        margin: 5px 0;
        font-size: 0.8rem;
        display: block;
        height: 20px;
    }
}

#secSection {
    display: none;
}

.newcContent {
    .tittle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 968px) {
            padding: 0 5px;
        }

        button {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            cursor: pointer;

            img {
                height: 100%;
            }
        }

        margin: 15px 0;

        * {
            font-size: 1.5rem;
        }
    }

}

table {
    width: 100%;

    @media (max-width:668px) {
        padding: 5px;
    }

    tr.header {
        background: var(--Bg-color-3);
    }

    tr {
        &:nth-child(odd) {
            background: #ccc;
        }

        th,
        td {
            padding: 3px 10px;
            font-size: 0.9rem;

            &.date {
                white-space: normal;
            }

            a {
                &.download {
                    color: var(--color-6);
                }
            }
        }

        td {
            border-bottom: 1px solid #ccc;
            padding: 10px 5px;
            vertical-align: inherit;

            img {
                height: 50px;
            }

            * {
                color: #000;
                text-decoration: none;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                white-space: normal;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

// ************************
.memberDit {
    gap: 15px;
    margin: 15px 0;

    @media (min-width: 668px) {
        display: flex;
    }

    @media (max-width:668px) {
        margin: 15px;
    }

    .imgpart {
        height: 200px;
        text-align: center;

        img {
            height: 100%;
        }
    }
}

.customTittle {
    h1 {
        background: var(--Bg-color-2);
        color: var(--color-1);
        padding: 8px;
        text-align: left;
        font-size: 0.7rem;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: -5px;
    }
}

.videoPart {
    margin: 15px 0;

    @media (max-width: 968px) {
        margin: 15px 5px;
    }

    .cstittle {
        margin: 15px 0;
    }

    .tittle {
        background: var(--Bg-color-2);
        color: var(--color-1);
        padding: 8px;
        text-align: left;
        font-size: 0.7rem;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;

        @media (max-width: 668px) {
            grid-template-columns: 1fr;
        }

        .videoDiv {
            height: auto;

            &.videoDiv3 {

                @media (min-width: 668px) {
                    grid-column: 1/3;
                    height: 300px;
                }
            }

            &.videoDiv8 {
                background: #aa9f01;

                @media (min-width: 668px) {
                    grid-column: 1/3;
                    height: 300px;
                }
            }
        }

    }

}

.photoGy {
    .tittle {
        background: var(--Bg-color-2);
        color: var(--color-1);
        padding: 8px;
        text-align: left;
        font-size: 0.7rem;
        font-weight: bold;
        margin-bottom: 10px;
    }

    @media (max-width: 968px) {
        margin: 0 5px;
    }

    .imgBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        row-gap: 5px;
        cursor: pointer;

        .card {
            margin: 0;

            &:hover {
                .imgPart {
                    img {
                        transform: scale(1.2);
                        transition: 0.5s ease;
                    }
                }
            }

            .imgPart {
                width: 100%;
                max-height: 200px;
                overflow: hidden;

                img {
                    width: 100%;
                    transition: 0.5s ease;
                }
            }

            .tittle {
                background: var(--Bg-color-2);
                color: var(--color-1);
                padding: 8px;
                text-align: left;
                font-size: 0.7rem;
                font-weight: bold;
                margin-bottom: 10px;
                margin-top: -5px;
            }
        }
    }

}

.bigImage {
    margin-bottom: 15px;

    img {
        width: 100%;
    }
}

.desitalServices {
    .contentPart {

        h1,
        h2 {
            font-size: 1rem;
        }

        ul {
            padding-left: 35px;
            margin: 15px 0;

            li {
                font-size: 0.9rem;
                margin: 05px 0;
            }
        }

        h2 {
            margin: 15px 0;
        }
    }
}

.scroll-x {
    @media (max-width: 668px) {
        overflow-x: scroll;
    }
}

.ContentPart {
    .pageTittle {
        margin: 10px 0;
    }
}

.contact {
    width: 100%;
    display: block;
    margin-bottom: 15px;

    .grid {
        display: grid;
        gap: 15px;

        div {
            &.massege {
                form {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    input,
                    button {
                        height: 40px;
                        padding: 0 10px;
                    }

                    textarea {
                        padding: 10px 10px;
                        font-size: 1rem;
                    }

                    button {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.optionsPart {
    .comment {
        position: fixed;
        top: 50%;
        right: 0;
        height: 40%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        margin-right: 15px;
        background: white;
        border-radius: 15px;
        box-shadow: 0px 0px 5px #a09f9fcc;
        cursor: pointer;

        @media (max-width: 668px) {
            top: 90%;
        }

        img {
            width: 100%;
        }
    }
}

.describe {
    h1 {
        margin: 15px;
    }
}

.headerCitigen {
    width: 100%;

    .row {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;

        .logo {
            img {
                height: 100px;
            }
        }

        .rightPart {
            h3 {
                font-size: 15px;
            }
        }
    }
}

.filterHeader {
    background: #03bd03;
    padding: 5px 15px;
    align-items: center;

    h3 {
        color: #000000;
    }

    div {
        padding: 5px 10px;

        select {
            border-radius: 3px;
            color: #000000;
            padding: 5px;
            background: linear-gradient(to top, #6eff6e, #dfdfdf);
            border: 0;
        }
    }
}

.modelSingleImg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: #000;
    display: grid;
    place-content: center;
    display: none;
    transform: scale(0.2);
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease-in-out;

    &.open {
        display: grid;
        transform: scale(1);
        visibility: visible;
        opacity: 1;
    }

    .cls_btn {
        position: absolute;
        right: 30px;
        top: 30px;
        width: 60px;
        height: 60px;
        color: #fff;
        font-size: 45px;
        transform: rotate(45deg);
        cursor: pointer;
        display: grid;
        place-content: center;
    }

    .img {
        height: 90%;

        img {
            width: 100%;
            margin: auto;
        }
    }
}